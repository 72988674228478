import React, { useEffect, useRef, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from '@mui/material';
import {
  Dialog,
  Button,
  Checkbox,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import {
  GetAttendanceForSessionVariables,
  GetAttendanceForSession,
  GetAttendanceForSession_getAttendanceForSession,
  AddOrUpdateCohortAttendanceVariables,
  AddOrUpdateCohortAttendance,
} from '../../../models/GeneratedModels';
import PageHeader from '../../global/PageHeader';
import ProgressIndicator from '../../global/ProgressIndicator';
import ViewAccountLink from '../Account/ViewAccountLink';
import { getAttendanceForSession_Gql } from '../../../gql/cohortAttendance/getAttendanceForSession';
import { addOrUpdateCohortAttendance_Gql } from '../../../gql/cohortAttendance/addOrUpdateCohortAttendance';
import SortableTableColumn, {
  SortProperty,
  complexSort,
  sortBy,
} from 'components/global/SortableTableColumn';
import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    iconLink: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  })
);

interface AttendanceProps {
  cohortId: string;
  sessionId: string;
  sessionName: string;
  sessionStartDate: Date;
  sessionEndDate: Date;
}

const Attendance = ({ cohortId, sessionId, sessionName, sessionStartDate, sessionEndDate }: AttendanceProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const sortPropDefault: SortProperty = {
    prop: 'name',
    order: 'asc'
  };
  const [sortProp, setSortProp] = useState<SortProperty>(sortPropDefault);


  const [attendance, setAttendance] = useState<
    GetAttendanceForSession_getAttendanceForSession[] | null
  >(null);
  const notesTextRefs = useRef<(HTMLInputElement | null)[]>([]);
  const notesDivRefs = useRef<(HTMLDivElement | null)[]>([]);

  const { loading, error, data } = useQuery<
    GetAttendanceForSession,
    GetAttendanceForSessionVariables
  >(getAttendanceForSession_Gql, {
    variables: {
      cohortId,
      sessionId,
    },
  });

  useEffect(() => {
    if (!data) return;
    
    // First, map the data to include the concatenated name
    const attendances = data.getAttendanceForSession?.map((row) => ({
      ...row,
      name: `${row.firstName} ${row.lastName}`.trim().toLowerCase() // Normalize the name for sorting
    })) || [];

    setAttendance(attendances);
  }, [data, sortProp]);

  const [updateAttendance] = useMutation<
    AddOrUpdateCohortAttendance,
    AddOrUpdateCohortAttendanceVariables
  >(addOrUpdateCohortAttendance_Gql);

  const onCheckboxChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const checked = e.target.checked;

    if (!attendance) return;

    setShowProgress(true);
    let updatedAttendance = [...attendance]; // Create a shallow copy of the array
    for (let i = 0; i < updatedAttendance.length; i++) {
      if (updatedAttendance[i].cohortRegistrationId === name) {
        // Create a new object with updated notes and replace the existing object
        updatedAttendance[i] = { ...updatedAttendance[i], isPresent: checked };
        break;
      }
    }

    // Update the notes property of the specific object
    setAttendance(updatedAttendance);

    const regMatch = attendance.filter((x) => x.cohortRegistrationId === name);

    if (regMatch) {
      await updateAttendance({
        variables: {
          cohortAttendance: {
            sessionAttendanceId: regMatch[0].sessionAttendanceId,
            cohortRegistrationId: regMatch[0].cohortRegistrationId,
            sessionId: sessionId,
            userId: regMatch[0].userId,
            present: checked,
            notes: regMatch[0].notes ? regMatch[0].notes : '',
          },
        },
      });
    }

    setShowProgress(false);
  };

  const handleAddNotes = async (index: number, cohortRegistrationId: string) => {
    const notesText = `${notesTextRefs.current[index]?.value}`;

    if (!attendance) return;

    setShowProgress(true);
    let updatedAttendance = [...attendance]; // Create a shallow copy of the array
    for (let i = 0; i < updatedAttendance.length; i++) {
      if (updatedAttendance[i].cohortRegistrationId === cohortRegistrationId) {
        // Create a new object with updated notes and replace the existing object
        updatedAttendance[i] = { ...updatedAttendance[i], notes: notesText };
        break;
      }
    }

    // Update the notes property of the specific object
    setAttendance(updatedAttendance);

    const regMatch = attendance.filter((x) => x.cohortRegistrationId === cohortRegistrationId);

    if (regMatch) {
      await updateAttendance({
        variables: {
          cohortAttendance: {
            sessionAttendanceId: regMatch[0].sessionAttendanceId,
            cohortRegistrationId: regMatch[0].cohortRegistrationId,
            sessionId: sessionId,
            userId: regMatch[0].userId,
            present: regMatch[0] && regMatch[0].isPresent ? regMatch[0].isPresent : false,
            notes: notesText ? notesText : '',
          },
        },
      });
    }

    if (notesDivRefs.current && notesDivRefs.current[index]) {
      const elem = notesDivRefs.current[index];
      if (elem !== null) {
        elem.style.display = 'none';
      }                              
    }
    setShowProgress(false);
  };

  const handleClearNotes = async (index: number, cohortRegistrationId: string) => {
    if (!attendance) return;

    setShowProgress(true);

    // Clear the TextField value
    if (notesTextRefs.current && notesTextRefs.current[index]) {
      const elem = notesTextRefs.current[index];
      if (elem !== null) {
        elem.value = '';
      }                              
    }

    let updatedAttendance = [...attendance]; // Create a shallow copy of the array
    for (let i = 0; i < updatedAttendance.length; i++) {
      if (updatedAttendance[i].cohortRegistrationId === cohortRegistrationId) {
        // Create a new object with updated notes and replace the existing object
        updatedAttendance[i] = { ...updatedAttendance[i], notes: ''};
        break;
      }
    }

    // Update the notes property of the specific object
    setAttendance(updatedAttendance);

    const regMatch = attendance.filter((x) => x.cohortRegistrationId === cohortRegistrationId);

    if (regMatch) {
      await updateAttendance({
        variables: {
          cohortAttendance: {
            sessionAttendanceId: regMatch[0].sessionAttendanceId,
            cohortRegistrationId: regMatch[0].cohortRegistrationId,
            sessionId: sessionId,
            userId: regMatch[0].userId,
            present: regMatch[0] && regMatch[0].isPresent ? regMatch[0].isPresent : false,
            notes: '',
          },
        },
      });
    }

    if (notesDivRefs.current && notesDivRefs.current[index]) {
      const elem = notesDivRefs.current[index];
      if (elem !== null) {
        elem.style.display = 'none';
      }                              
    }
    setShowProgress(false);
  };

  return (
    <>
      <ProgressIndicator title="Loading" isOpen={loading || showProgress} />

      <span className={classes.iconLink} onClick={() => setIsOpen(true)}>
        Attendance
      </span>
      <Dialog fullScreen open={isOpen}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          <PageHeader title={sessionName} />
          <PageHeader title={`Session: ${convertDateToTimeZoneFromUtc(sessionStartDate, true, false, false)}`} />        
             
        </DialogTitle>
        <DialogContent>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>

          <TableContainer sx={{ mt: 3 }} component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow sx={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 10 }}>
                  <TableCell align="center">Present</TableCell>

                  <SortableTableColumn
                    currentSort={sortProp?.prop}
                    onSort={setSortProp}
                    sortProperty="name"
                    headerText="Attendee"
                    order={sortProp?.order                      
                  }
                  />

                  <TableCell>Credentials</TableCell>

                  <TableCell>Organization</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendance &&
                  complexSort(attendance, sortProp.order ? sortProp : sortPropDefault).map((att, index) => (
                    <TableRow
                      key={att.cohortRegistrationId}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center">
                        <Checkbox
                          name={att.cohortRegistrationId}
                          onChange={onCheckboxChange}
                          checked={!att.isPresent ? false : att.isPresent}
                        />
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <ViewAccountLink
                          userId={att.userId}
                          name={`${att.firstName} ${att.lastName}`}
                          email={att.email}
                        />
                      </TableCell>

                      <TableCell>{att.credentials}</TableCell>

                      <TableCell>{att.organization}</TableCell>

                      <TableCell key={index}>
                        <TextField
                          sx={{ width: 350, mb: 3 }}
                          label=""
                          name={`notes${index}`}
                          defaultValue={att.notes}
                          variant="outlined"
                          color="warning"
                          inputRef={ref => {
                            notesTextRefs.current[index] = ref;
                          }}
                          
                          onFocus={() => {
                            if (notesDivRefs.current && notesDivRefs.current[index]) {
                              const elem = notesDivRefs.current[index];
                              if (elem !== null) {
                                elem.style.display = 'inline';
                              }                              
                            }
                          }}
                          onClick={() => {
                            if (notesDivRefs.current && notesDivRefs.current[index]) {
                              const elem = notesDivRefs.current[index];
                              if (elem !== null) {
                                elem.style.display = 'inline';
                              }                              
                            }
                          }}
                        />
                        <br />
                        <div 
                          ref={(el) => notesDivRefs.current[index] = el} 
                          style={{ display: 'none' }}>
                          <Button 
                            onClick={(e) => handleAddNotes(index, att.cohortRegistrationId)}
                            color="primary" 
                            variant="contained">
                            Add/Update
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button  
                            onClick={(e) => handleClearNotes(index, att.cohortRegistrationId)} 
                            color="primary" 
                            variant="contained">
                            Clear
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button
                            color="primary" 
                            variant="outlined"
                            onClick={() => {
                              if (notesDivRefs.current && notesDivRefs.current[index]) {
                                const elem = notesDivRefs.current[index];
                                if (elem !== null) {
                                  elem.style.display = 'none';
                                }                              
                              }
                            }}>
                            Cancel
                          </Button>
                        </div>                  
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Attendance;
